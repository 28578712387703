import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultsService } from '../defaults/defaults.service';
import { Platform } from '@ionic/angular';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

    constructor(
        private http: HttpClient, 
        private storage: Storage, 
        private defaults: DefaultsService,
        private platform: Platform,
        //private device: Device,
       // private diagnostic: Diagnostic,
        //private app: App
        ) {}

    init(){
        //Called when app is loaded

        //Will not run analytics if in dev environment
        if(this.defaults.production){        

            this.durationOfUse();
            this.getGeoInfo();
            this.isPaused();  
            this.getPhoneInfo();
            this.watchForPageView();

            //Analytics that is only for phones
            if(this.platform.is('cordova')){
                
                this.getPermissions();
            }
        }
    }
    buttonClick(button){
        //Send button to backend
    }
    scrollBottom(page){
        //Counting the times users scroll to bottom of page to load more of a list
    }

    private getGeoInfo(){
        this.http.get<any>('https://ipinfo.io/geo')
            .subscribe(res=>{
                //Check if country is same as before
                //Then send to api
                //OR do not run this at all if country is stored locally from before. 
            })
    }
    private getPhoneInfo(){
        //Device was bugged, waiting for ionic update

        /*this.device.manufacturer; //Device manufactures (ie. samsung)
        this.device.model; //Device name (ie. samsung s8)
        this.device.version; //OS version
        this.device.platform; //OS name*/
    }
    private getPermissions(){
        let permissions = {
            location: false,
            locationAuthorized: false,
            locationAvailable: false,
            camera: false,
            notifications: false,
        }
/*
        this.diagnostic.isLocationEnabled().then(locationEnabled=>{
            permissions.location = locationEnabled;
        })
        this.diagnostic.isLocationAvailable().then(locationAvailable=>{
            permissions.locationAvailable = locationAvailable;
        })
        this.diagnostic.isLocationAuthorized().then(locationAuthorized=>{
            permissions.locationAuthorized = locationAuthorized;
        })*/
        setTimeout(()=>{
            //Give it a second, then call the db

        }, 1000);

        
    }
    private durationOfUse(){
        //Get date when initialized, then when app is closed/paused
        //let now = new Date();
    }
    private watchForPageView(){
      /*  let page;
        this.app.viewWillEnter.subscribe((res: ViewController)=>{
            if(res.component.name.toString().toLowerCase()==='modalcmp'){     
                page = res.data.component.name;
            }else{
                page = res.component.name;
            }
            //Send page here
        })*/
        
    }

    private isPaused(){
        document.addEventListener('pause', ()=>{
            let date = new Date();
            this.storage.set('closed', date);
        }, false);
        window.onbeforeunload = ()=>{
            let date = new Date();
            this.storage.set('closed', date);
        }
    }
}
