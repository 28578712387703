import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { AuthComponent } from 'src/app/components/auth/auth.component';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { AppConfig } from 'src/app/variables';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  appConfig: AppConfig = new AppConfig();
  isFellTech: boolean = false;
  constructor(
    private authService: AuthService,
    private modalCtrl: ModalController,
    private router: Router,
    private storage: Storage
  ) {
    // TODO: to be removed after testing with abra login flow
    this.storage.get('environment').then((env) => {
      this.isFellTech = this.appConfig.appToken == '62d8aee3-7e03-472c-8067-6e78a4ed1497' && env === 'staging';
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return new Observable<boolean>((observer) => {
      this.authService.authenticate().subscribe((authenticated) => {
        if (authenticated) {
          if (state.url.includes('/orders')) {
            //Skip adhoc check for orders
            observer.next(true);
            observer.complete();
          } else {
            observer.next(true);
            observer.complete();
          }
        } else {
          this.promptAuth(state.url);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }
  private promptAuth(navigateTo: string, adhoc?): Promise<any> {
    return new Promise((resolve) => {
      this.modalCtrl
        .create({
          component: AuthComponent,
          componentProps: {
            showLogin: true,//navigateTo === '/login' || this.isFellTech  ? true : false,
            adhoc: adhoc
          }
        })
        .then((modal) => {
          modal.onDidDismiss().then((res: OverlayEventDetail) => {
            if (res.data) {
              this.router.navigate([navigateTo]);
            } else {
              //Stay on view if closed
              //this.router.navigate(['stations']);
            }
          });
          modal.present();
        });
    });
  }
}

