import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ConvertSecondsPipe } from './convert-seconds/convert-seconds.pipe';
import { AppCardPipe } from './app-card/app-card.pipe';
import { CountPipe } from './count/count.pipe';
import { KmOrMPipe } from './km-or-m/km-or-m.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { MinMaxKwhPipe } from './min-max-kwh/min-max-kwh.pipe';
import { IsChargingPipe } from './is-charging/is-charging.pipe';
import { CustomCurrencyPipe } from './custom-currency/custom-currency.pipe';
import { CustomDatePipe } from './custom-date/custom-date.pipe';
import { SecMinHourPipe } from './sec-min-hour/sec-min-hour.pipe';
import { MaxLengthPipe } from './max-length/max-length.pipe';
import { WOrKwPipe } from './w-or-kw/w-or-kw.pipe';
import { ActionPipe } from './action/action.pipe';
import { LoggedInPagesPipe } from './logged-in-pages/logged-in-pages.pipe';



@NgModule({
  declarations: [
      ConvertSecondsPipe, 
      AppCardPipe, 
      CountPipe, 
      KmOrMPipe,
      FilterPipe,
      MinMaxKwhPipe,
      IsChargingPipe,
      CustomCurrencyPipe,
      CustomDatePipe,
      SecMinHourPipe,
      MaxLengthPipe,
      WOrKwPipe,
      ActionPipe,
      LoggedInPagesPipe
    ],
  imports: [
    CommonModule
  ],
  exports: [
    ConvertSecondsPipe, 
    AppCardPipe, 
    CountPipe, 
    KmOrMPipe,
    FilterPipe,
    MinMaxKwhPipe,
    IsChargingPipe,
    CustomCurrencyPipe,
    CustomDatePipe,
    SecMinHourPipe,
    MaxLengthPipe,
    WOrKwPipe,
    ActionPipe,
    LoggedInPagesPipe
  ],
  providers: [DecimalPipe]
})
export class PipesModule { }
