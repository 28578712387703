import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPaymentComponent } from './add-payment.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AddPaymentDropinModule } from '../add-payment-dropin/add-payment-dropin.module';



@NgModule({
    declarations: [AddPaymentComponent],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
        AddPaymentDropinModule
    ],
    exports: [AddPaymentComponent]
})
export class AddPaymentModule { }
