import { NgModule } from '@angular/core';
import { SelectAllDirective } from './select-all.directive';
import { CanSeeDirective } from './can-see.directive';
import { HelpdeskButtonDirective } from './helpdesk-button.directive';

@NgModule({
  declarations: [CanSeeDirective, HelpdeskButtonDirective, SelectAllDirective],
  exports: [CanSeeDirective, HelpdeskButtonDirective, SelectAllDirective]
})
export class DirectivesModule {}
