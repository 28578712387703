import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavController, NavParams, ModalController, AlertController } from '@ionic/angular';
import { HttpInterceptorService } from 'src/app/services/http-interceptor/http-interceptor.service';
import { GlobalsService } from 'src/app/services/globals/globals.service';
import { TranslateService } from '@ngx-translate/core';
import { StationsService } from 'src/app/services/stations/stations.service';
import { UserService } from 'src/app/services/user/user.service';
import { ReplaySubject } from 'rxjs';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Storage } from '@ionic/storage-angular';
import { LocationService } from 'src/app/services/location/location.service';

@Component({
  selector: 'sc-new-card',
  templateUrl: './new-card.component.html',
  styleUrls: ['./new-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewCardComponent implements OnInit {

    customerID: any;
    loading: boolean = true;
    pushedPage: boolean = false;
    showPoints: boolean = false;
    points: any = [];
    claimedPoints: any = [];
    point: any;
    presetPoint: any;
    scanning: boolean = false;
    scanningInterval: any;
    station:any;
    stations: any = [];
    accessCardIDs: any = []; //For comparison
    accessCard: any; //Holding the new card when added
    requestID: number;

    items: any = [];
    page: string; //page that the modal originated from
    mustPlugFirst: boolean = false;
    socketIniated: boolean = false;
    search: any;
    searchValue: string;
    searchedItems: any = [];

    constructor(
        public navCtrl: NavController, 
        public navParams: NavParams,
        private modalCtrl: ModalController,
        public _http: HttpInterceptorService,
        public gf: GlobalsService,
        private alertCtrl: AlertController,
        private translate: TranslateService,
        private stationsCtrl: StationsService,
        private userService: UserService,
        private ws: WebsocketService,
        private authService: AuthService,
        private storage: Storage,
        private locationService: LocationService
    ) {
        this.pushedPage = this.navParams.get('pushedPage');
    }

    ionViewWillLeave(){
        clearInterval(this.scanningInterval);
        this.clearSearch();
    }
    ngOnInit(){
      
        //this.accessCards = this.navParams.get('accessCards');
        this.initSocket();

        this.page = this.navParams.get('page');
        this.point = this.navParams.get('presetPoint');
        this.presetPoint = this.navParams.get('presetPoint');

        if(this.point){
            //Happens when this page is opened from claimed points
            //Need to modify point a bit since it is not the same object
            this.point.PK_ChargeBoxID = this.point.FK_ChargingBoxID; 
            this.point.PK_ChargePointID = this.point.FK_ChargePointID
        }
        

        this.userService.getCustomerID().then(customerID=>{
            this.customerID = customerID;
            this.getAccessCards(true);
            
            if(this.point){
                this.checkBox(this.point)
            }else{
                this.getStations();
                this.getClaimedPoints();
            }

        
            
        })

        
     
    }
    ionViewDidEnter(){
 
    }
    initSocket(){
        this.ws.clear();
        this.userService.getCustomerID().then(customerID=>{
            
            this.locationService.getUserLocation().then(loc=>{
            
                this.ws.init(customerID, loc.latitude, loc.longitude);
   
                 //Init stations ws
                this.search = this.ws.stations.subscribe(res=>{
               
                    if(res instanceof Array){
                        res.map(item=>{
                            item.ChargerCode = item.Code;
                        })
                        this.loading = false;
                        this.searchedItems = res;  
                        this.items = res;
                    }
                })

                
            })
            
        }) 
    }
    clearSearch(){
        this.searchedItems = [];
        this.searchValue = "";
        this.items = [...this.stations];
        this.loading = false;
    }

    waitForIdle: any;
    searchStations(ev: any) {
        this.loading = true;
        this.showPoints = false;
        if(this.waitForIdle){
            clearTimeout(this.waitForIdle);
        }

        this.searchValue = ev.srcElement.value;
        if(!this.searchValue){
            this.clearSearch();
            return;
        }
        let search = {
            Text: this.searchValue,
            Nb: 4,
            OnlySmartCharge: true
        }

        
        this.waitForIdle = setTimeout(()=>{
 
            this.ws.socketInitator.subscribe(done=>{

                if(this.socketIniated){
                    this.ws.stations.next(search);
                }else{
                    this.socketIniated = true;
                    this.storage.get('token').then(token=>{
                        if(!token) token = ""; //Empty token for unauthenticated users
                        this.ws.stations.next(token);
                        this.ws.stations.next(search);
                    })
                }
            })
        }, 500);
    }
    getStations(){
       
        this.stationsCtrl.getStations(this.customerID, false, true).then(stations=>{
            this.loading = false;
            if(!this.searchedItems.length){
                //Don't fill items if search is already done
                this.stations = stations;
                this.items = stations;
            }
            
        })
       
    }
    getClaimedPoints(){
        this.userService.getClaimedPoints().then(points=>{
            this.claimedPoints = points.map(point=>{
                point = Object.assign(point, point.ExtraInformation.GenericPoint)
                return point;
            });
        })
    }
    openOrScan(item){
        //this.searchedItems = []; //Clear search
        if(item.PK_ChargePointID){
            //Point
            this.point = item;
            this.checkBox(this.point);
        }else if(!item.PK_ChargePointID && (item.ID || item.PK_ChargingStationID)){
            //Station from search
            this.loading = true;
            let stationID = item.ID || item.PK_ChargingStationID;
            this.items = [];
            this.stationsCtrl.getStation(stationID).then(res=>{
                this.loading = false;
                this.showPoints = true;
                var collator = new Intl.Collator('en', {numeric: true, sensitivity: 'base'});
                this.items = res.ChargingPoints = res.ChargingPoints.sort((a, b) => collator.compare(a.Name, b.Name))
                if(res.ChargingPoints && res.ChargingPoints.length===1){
                    //In case of one point, no need to make the user click it
                    this.point = res.ChargingPoints[0];
                    this.checkBox(this.point);
                }
            })
        }/*else{
            //Station
            if(item.ChargingPoints && item.ChargingPoints.length===1){
                //In case of one point, no need to make the user click it
                this.point = item.ChargingPoints[0];
                this.checkBox(this.point);
            }else{
                //Showing points
                this.showPoints = true;
                this.items = item.ChargingPoints;
                
                //this.navCtrl.navigateForward('new-card', {points: item.ChargingPoints, page: this.page, pushedPage: true})
            }
        }*/
    }
    initScanning(point){
        this.scanning = true;
        let obj = {
            FK_CustomerID: this.customerID,
            FK_ChargingBoxID: point.PK_ChargeBoxID
        }

        this._http.post('AccessCards/WaitForRFIDSwipe', obj).subscribe(res=>{
            this.requestID = res.datas.requestForSwipe;

            this.scanningInterval = setInterval(()=>{
                this.searchForRFID();
            }, 1000);
        })
    }
    checkBox(point, redo?){
        if(point.State && point.State === 'Offline'){
            this.showError(this.translate.instant('MY_CHARGERS.OFFLINE_BOX'), this.translate.instant('MY_CHARGERS.OFFLINE_BOX_DESCRIPTION'));
            this.point = null;
        }else{

            if(point.ExtraInformation && point.ExtraInformation.needToPlugBeforeStart){
                this.mustPlugFirst = true;
            }else if(!point.ExtraInformation && !redo){
                //Should show some loading
                this._http.get('ChargePoints/'+point.PK_ChargePointID + '/true').subscribe(res=>{
                    this.point = res;
                    this.checkBox(this.point, true);
                })
            }else{
                this.initScanning(point);
                this.mustPlugFirst = false;
            }  
        }
    }
    getAccessCards(first?):Promise<any>{
        return new Promise(resolve=>{
            this._http.get('AccessCards/ByCustomer/'+this.customerID).subscribe(res=>{
                if(first){
                    //First time, used for comparison for later
                    res.forEach(card => {
                        this.accessCardIDs.push(card.PK_AccessCardID);
                    });
                } 
                resolve(res);
            })
        })
        
    }
    availableItems(){
        return this.items;
        if(this.searchedItems){
            return this.searchedItems;
        }else{

            return this.items;
           /* return this.items.filter(item=>{
                if(item.ChargingPoints || item.ID){
                    //Station (ws stations have ID)
                    if(item.totalBoxes>item.offlineBoxes || item.ID){
                        return item;
                    }
                }else{
                    //Point (ws points do not have state)
                    if(!item.State || item.State==='Online'){
                        //FIX: Check here if boxmodel can add card
                        return item;
                    }
                }
            })*/
        }
    }
    searchForRFID(){
        this._http.get('AccessCards/GetRegisterRFIDRequest/'+this.requestID).subscribe(res=>{
            if(res){
                
                if(res.datas){
                    
                    if(res.datas.result==='AlreadyExists'){
                        //Already existing
                        this.showError(this.translate.instant('ACCESS_CARDS.RFID_EXISTS'));
                        this.startOver();
                    }else if(res.datas.result==='TooSlowToSwipe'){
                        //Too slow, try again
                        //FIX: Create error message
                        this.startOver();
                    }
                }
            }
            
        }, err=>{
            //Notfound means it is added
            if(err.errorName && err.errorName==="notFoundError"){
                clearInterval(this.scanningInterval);
                //Check if a new card is actually added
                this.getAccessCards().then(cards=>{
                    if(cards.length>this.accessCardIDs.length){
                        //Card added. Get it. Matching old and new cards
                        this.accessCard = cards.filter(card=>this.accessCardIDs.indexOf(card.PK_AccessCardID)===-1)[0];
                    }else{
                        //Strange error. Means the requestID is invalid
                        this.startOver();
                    }
                })
            }
        })
    }
    addName(){
        this.alertCtrl.create({
            header: this.translate.instant('ACCESS_CARDS.ADD_NAME'),
            inputs: [
                {
                    name: 'cardName',
                    placeholder: this.translate.instant('ACCESS_CARDS.CARD_NAME')
                }
            ],
            buttons: [
                {
                    text: this.translate.instant('APP.CLOSE'),
                    handler: ()=>{
                        this.cancel(true);
                    }
                },
                {
                    text: this.translate.instant('APP.SAVE'),
                    handler: data => {
                        this.accessCard.CardName = data.cardName;
                        this.updateCard();
                    }
                }
            ]
        }).then(prompt=>{
            prompt.present();
        })
        
    }
    closePoints(){
        this.items = this.stations;
        this.showPoints = false;
    }
    updateCard(){
        this.accessCard.LastModifiedOn = this.gf.getDateAsUTC();

        this._http.put('AccessCards', this.accessCard)
            .subscribe(res=>{
                this.cancel(true);
            });
    }
    cancel(cardAdded?){
        this.modalCtrl.dismiss(cardAdded)
    }
    startOver(){    
        if(this.navParams.get('presetPoint')){
            this.initScanning(this.point);
        }else{
            this.scanning = false;
        }
        this.point = null;
        this.accessCard = null;
        this.showPoints = false;

        this.requestID = null;
        clearInterval(this.scanningInterval);
       /* if(this.navCtrl.getViews().length>1){
            this.navCtrl.pop();
        }*/
    }
    showError(title, subTitle?){
        this.alertCtrl.create({
            header: title,
            subHeader: subTitle || '',
            buttons: ['OK']
        }).then(alert=>{
            alert.present();
        })
        
    }

}
