import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';

const PIPES = [CurrencySymbolPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...PIPES],
  exports: [...PIPES]
})
export class SharedModule {}
