import { Injectable } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ActionSheetController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private platform: Platform,
    private callNumber: CallNumber,
    private translate: TranslateService,
    private inAppBrowser: InAppBrowser,
    private actionSheetCtrl: ActionSheetController

    ) { }

    hasSupport(station){
      if(!station || !station.SupportInformation) return false;
    
      let support = station.SupportInformation;
      if(!support.PhoneNumberString && !support.SupportChat && !support.SupportEmail && !support.SupportCustomerServiceURL) return false;
      return true;
    }
  openSupport(station, showOperatorName?){
    let buttons = [];
    let support = station.SupportInformation;

    if(support.PhoneNumberString){
        buttons.push({
            text: support.PhoneNumberString,//this.translate.instant("SUPPORT.CALL"),
            icon: 'call-outline',
            handler: () => {
                if(this.platform.is('cordova')){
                    this.callNumber.callNumber(support.PhoneNumberString, true);
                }else{
                    window.open("tel:"+support.PhoneNumberString, "_system", 'hidden=yes,location=no');

                }
               
            }
        })
    }
    if(support.SupportChat){
        buttons.push({
            text: this.translate.instant("SUPPORT.CHAT"),
            icon: 'chatbubbles-outline',
            handler: () => {
                this.inAppBrowser.create(support.SupportChat, "_system")
            }
        })
    }
    if(support.SupportEmail){
        buttons.push({
            text: support.SupportEmail,//this.translate.instant("SUPPORT.EMAIL"),
            icon: 'mail-outline',
            handler: () => {
                if(this.platform.is('cordova')){
                    this.inAppBrowser.create("mailto:"+support.SupportEmail, "_system")
                }else{
                    window.open("mailto:"+support.SupportEmail, "_system", 'hidden=yes,location=no');

                }
            }
        })
    }
    if(support.SupportCustomerServiceURL){
        buttons.push({
            text: this.translate.instant("SUPPORT.SUPPORT_CENTER"),
            icon: 'help-circle-outline',
            handler: () => {
                this.inAppBrowser.create(support.SupportCustomerServiceURL, "_system")
            }
        })
    }

    buttons.push({
        text: this.translate.instant("APP.CANCEL"),
        icon: 'close-outline',
        role: 'cancel'
    })
    this.actionSheetCtrl.create({
        header: this.translate.instant("SUPPORT.TITLE") + (station.OperatorName && showOperatorName ? " (" + station.OperatorName + ")" : ""),
        buttons: buttons
      }).then(sheet=>{
          sheet.present();
      })
     
}
}
