import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from './providers';
import { AppConfig } from './types';
import { StorageService } from '../storage';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly storageService: StorageService
  ) {}

  get<K extends keyof AppConfig>(key: K): AppConfig[K] {
    return this.appConfig[key];
  }

  async getLanguage(): Promise<string> {
    let lang;

    try {
      lang = await this.storageService.get('lang');

      if (!lang || !this.get('availableLangs').includes(lang)) {
        lang = this.get('defaultLang');

        await this.storageService.set('lang', lang);
      }
    } catch {
      // If storage doesn't work for some reason, use default
      lang = this.get('defaultLang');
    }

    return lang;
  }
}
