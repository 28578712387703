import { Injectable } from '@angular/core';
import { Subject, Observable, Observer, ReplaySubject } from 'rxjs';
import { DefaultsService } from '../defaults/defaults.service';
import { map } from 'rxjs/operators';
import { AppConfig } from 'src/app/variables';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

    private subject: Subject<MessageEvent>;
    public stations: Subject<any>;
    socket:WebSocket;
    appConfig: AppConfig = new AppConfig();
    socketInitator: ReplaySubject<any> = new ReplaySubject();


    constructor(private defaults: DefaultsService, private storage: Storage) { }


    public init(customerID=0, lat, lon){

        if(this.stations) this.clear();

        this.stations = <Subject<any>>this
            .connect(this.defaults.socket+'ChargingStations/StationsByNameApp/'+customerID+'/'+lat+'/'+lon+'/'+this.appConfig.appToken).pipe(
            map((response: MessageEvent): any => {

                let data = JSON.parse(response.data);

                return data;
            }));



    }

    public initPortal(customerID: number) {
      if(this.stations) this.clear();
      this.stations = <Subject<any>>this.connect(`${this.defaults.socket}ChargingStations/StationsByNameDashboard/${customerID}`)
      .pipe(map((response: MessageEvent): any => {
        let data = JSON.parse(response.data);
        return data;
      }));
    }

    public connect(url): Subject<MessageEvent> {
        if (!this.subject) {
            this.subject = this.create(url);
        }
        return this.subject;
    }
    private create(url): Subject<MessageEvent> {

        this.socket = new WebSocket(url);

        this.socket.addEventListener('open', (ev)=>{

            this.socketInitator.next(true);
            this.socketInitator.complete();
        })



        let observable = Observable.create((obs: Observer<MessageEvent>) => {
            this.socket.onmessage = obs.next.bind(obs);
            this.socket.onerror = obs.error.bind(obs);
            this.socket.onclose = obs.complete.bind(obs);
            return this.socket.close.bind(this.socket);
        })

        let observer = {
            next: (data: Object) => {

                //Check if socket is open before sending data
                if (this.socket.readyState === WebSocket.OPEN) {
                    this.socket.send(JSON.stringify(data));
                }else{
                    //Wait for socket to open

                    this.socketInitator.subscribe(done=>{
                        this.socket.send(JSON.stringify(data));
                    })

                    /*this.socket.addEventListener('open', (val)=>{
                        this.socket.send(JSON.stringify(data));
                    })*/
                }
            }
        }
        return Subject.create(observer, observable);
    }
    public clear(){
        this.socketInitator.complete();
        this.socketInitator = new ReplaySubject();
        if(this.stations){
            this.stations.unsubscribe();
            this.stations = null;
        }
        if(this.subject){
            this.subject.unsubscribe();
            this.subject = null;
        }
        if(this.socket){
            this.socket.close();
            this.socket = null;
        }

    }

}
