import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { StationsService } from '../stations/stations.service';
import MarkerClusterer from 'node-js-marker-clusterer';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsClusterService {
    markerCluster: any;


    constructor(private stationsCtrl: StationsService) {}

    addCluster(map){
       /* if(google.maps){
            let markers = this.stationsCtrl.stations.map(station=>{
                return new google.maps.Marker({
                    position: {lat: station.Latitude, lng:station.Longitude}
                });
            });

            this.markerCluster = new MarkerClusterer(map, markers, {imagePath: 'assets/m/m'});
        
        }else{
            console.warn('Google maps needs to be loaded before adding a cluster');
        }*/
    }

}
