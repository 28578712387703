import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {
    constructor(
        private platform: Platform, 
        private launchNavigator: LaunchNavigator,
        private translate: TranslateService
    ){}
    go(lat, lng){
        
        let destination = lat + "," + lng;

        if(this.platform.is('cordova')){
            //Mobile, find out what apps are available
            this.launchNavigator.availableApps().then(apps=>{
                
                if(apps["google_maps"] && apps["apple_maps"]){
                    //If the user has both apple and google maps we let them chose
                    this.navigate(destination, null);
                }else if(apps["google_maps"]){
                    //Open google maps
                    this.navigate(destination, this.launchNavigator.APP.GOOGLE_MAPS);
                }else if(apps["apple_maps"]){
                    //Open apple maps
                    this.navigate(destination, this.launchNavigator.APP.APPLE_MAPS);
                }else{
                    //Neither apple or google maps, could be that the user has something else.
                    this.navigate(destination, null);
                }
              
            })

        }else{
            window.open('https://www.google.com/maps/dir/?api=1&destination='+destination, "_blank")
        }
    }

    private navigate(destination, selectedApp){

        let options: LaunchNavigatorOptions = {
            app: selectedApp,
            appSelection: {
                dialogHeaderText: this.translate.instant('NAVIGATION.SELECT_APP'),
                cancelButtonText: this.translate.instant('APP.CANCEL'),
                rememberChoice: {
                    prompt: {
                        headerText: this.translate.instant('NAVIGATION.REMEMBER_CHOICE'),
                        bodyText: this.translate.instant('NAVIGATION.USE_SAME_APP'),
                        yesButtonText: this.translate.instant('APP.YES'),
                        noButtonText: this.translate.instant('APP.NO')
                    }
                }
            }
        };
       
        this.launchNavigator.navigate(destination, options).then(res=>{

        })
    }
}
