import { Injectable } from '@angular/core';
import { LocationService } from '../location/location.service';

@Injectable({
  providedIn: 'root'
})
export class DistanceService {
    constructor(private locationService: LocationService) { }

    public getDistance(stations):Promise<any>{
        return new Promise(resolve=>{
            this.locationService.getUserLocation().then(location=>{


                if(!location.trueGps){
                    stations.map((station)=>{
                        station.distance = "NaN"; 
                        //Why NaN? 
                        //If there is a bug and the service can't calculate the distance, it will return NaN.
                        //ListPage will not show distance or NaN if distance is NaN
                    });
                    resolve(stations);
                }else{

                    let userLocation = {
                        lat: location.latitude,
                        lng: location.longitude
                    };
                    stations.map((station) => {
                        let stationLocation = {
                            lat: station.Latitude,
                            lng: station.Longitude
                        };
                        
                        station.distance = this.getDistanceBetweenPoints(
                            userLocation,
                            stationLocation,
                            'km'
                        ).toFixed(2);
                    });
                    resolve(stations);
                }
                
            }).catch((error)=>{

                stations.map((station)=>{
                    station.distance = "NaN";
                });
                resolve(stations);

            });
        })
    }
    getDistanceBetweenPoints(start, end, units){
    
        let earthRadius = {
            miles: 3958.8,
            km: 6371
        };

        let R = earthRadius[units || 'km'];
        let lat1 = start.lat;
        let lon1 = start.lng;
        let lat2 = end.lat;
        let lon2 = end.lng;

        let dLat = this.toRad((lat2 - lat1));
        let dLon = this.toRad((lon2 - lon1));
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c;

        return d;
    
    }
    private toRad(x){
        return x * Math.PI / 180;
    }
}
