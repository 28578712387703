import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { GlobalsService } from '../globals/globals.service';
import { Storage } from '@ionic/storage-angular';
import { TokenService } from '../token/token.service';
//import { BrandingService } from '../branding/branding.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAsService {

    constructor(
        public _http: HttpInterceptorService,
        private gf: GlobalsService,
        //private brandingService: BrandingService,
        private userService: UserService,
        private storage: Storage,
        private tokenService: TokenService,
        private location: Location
    ) {}
        authAs(email){
            let appID = this.gf.guid4();
            this.storeLoggedInUser().then(()=>{

                this._http.post('Users/AuthenticateAs', {
                    Email: email,
                    appID: appID,
                    isDashboard: false
                }).subscribe(res=>{
                    //this.brandingService.clear();
                    //this.brandingService.checkMorel(email);


                    //Customer holds enough info
                    this.userService.storeUser(res.customer);
                    //this.storeCustomer(res.customer);

                    this.storage.set('rToken', res.rToken);
                    this.storage.set('appid', appID);
                    this.tokenService.token = res.accessToken;
                    this.storage.set('token', res.accessToken).then(success=>{
                        //Important that the token is stored before the user can go on.
                        this.location.go('');
                        location.reload();
                    })
                })
            })
        }

        logout(){
            this.storage.get('loggedInUser').then(loggedInUser=>{
                this.storage.remove('loggedInUser');

                //this.storeCustomer(res.customer);

                this.storage.set('rToken', loggedInUser.rToken);
                this.storage.set('appid', loggedInUser.appid);
                this.tokenService.token = loggedInUser.accessToken;
                this.storage.set('token', loggedInUser.accessToken || loggedInUser.token).then(success=>{
                    //Important that the token is stored before the user can go on.
                    this.location.go('');
                    location.reload();
                })
            })
        }
        private storeLoggedInUser():Promise<any>{
            return new Promise(resolve=>{
                Promise.all([
                    this.storage.get('rToken'),
                    this.storage.get('token'),
                    this.storage.get('appid'),

                ]).then(([rToken, token, appid])=>{
                  let loggedInUser = {
                        rToken: rToken,
                        token: token,
                        appid: appid
                    }
                    this.storage.set('loggedInUser', loggedInUser)
                    resolve(null);
                })
            })


            //localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
        }
}
