//Angular
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

//Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Storage, IonicStorageModule } from '@ionic/storage-angular';

//External
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

//Native
//import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
//import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

//Translate
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//Internal
import { AuthModule } from './components/auth/auth.module';
import { Services } from './services/services';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PipesModule } from './pipes/pipes.module';
import { LOCATION_INITIALIZED } from '@angular/common';
import { AppConfig } from './variables';
import { version as appVersion } from './version';
import { DefaultsService } from './services/defaults/defaults.service';
import { SentryIonicErrorHandler } from './error-handler';
import { LanguageService } from './services/language/language.service';
import { DirectivesModule } from './directives/directives.module';
import { LoginAlertModule } from './components/login-alert/login-alert.module';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { APP_CONFIG } from '@smartcharge/shared/core/config';
import { STORAGE } from '@smartcharge/shared/core/storage';
import {
  Environment,
  EnvironmentService
} from '@smartcharge/shared/core/environment';
import { SharedModule } from '@smartcharge/shared/shared.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v=' + appVersion
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage]
      }
    }),
    AppRoutingModule,
    PipesModule,
    HttpClientModule,
    AuthModule,
    DirectivesModule,
    SharedModule,
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1IjoiY3VycmVudGVjbyIsImEiOiJjbDJoamp5Y3EwZWJvM2ZvamJpeXprdTJqIn0.oKqB4iEAAOVVT2WjTUtmFQ' // Optional, can also be set per map (accessToken input of mgl-map)
    })
    /*AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBNAJJ0c9-VScOE6H78bO-pWqKBAwqZg7E',
            libraries: ['places']
        })*/
  ],
  providers: [
    //Native
    StatusBar,
    SplashScreen,
    //Diagnostic,
    Geolocation,
    InAppBrowser,

    LaunchNavigator,
    LocationAccuracy,

    Keyboard,
    //OneSignal,
    OpenNativeSettings,
    CallNumber,
    Storage,
    TranslateService,
    LoginAlertModule,
    Deeplinks,
    //end Native
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_CONFIG,
      useValue: new AppConfig()
    },
    {
      provide: STORAGE,
      useExisting: Storage
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [
        TranslateService,
        Injector,
        Storage,
        DefaultsService,
        LanguageService,
        APP_CONFIG,
        EnvironmentService
      ],
      multi: true
    },
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
    //Services
    Services
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  storage: Storage,
  defaults: DefaultsService,
  languageService: LanguageService,
  config: AppConfig,
  environmentService: EnvironmentService
) {
  return () =>
    new Promise<any>((resolve: any) => {
      storage.create().then(
        async () => {
          await defaults.init();

          //Initializes the translateservice before the app loads. This is so the translations show correctly
          const locationInitialized = injector.get(
            LOCATION_INITIALIZED,
            Promise.resolve(null)
          );
          locationInitialized.then(() => {
            translate.addLangs(config.availableLangs);
            translate.setDefaultLang(config.defaultLang);

            languageService.getLanguage().then((lang) => {
              translate.use(lang);
              resolve(null);
            });
          });
        },
        (err) => {
          //Could not init storage. App won't work in this case.
          console.log(err);
          resolve(null);
        }
      );
    });
}

export function jwtOptionsFactory(storage: Storage) {
  return {
    tokenGetter: () => {
      return storage.get('token').then((value) => {
        return value;
      });
    },
    allowedDomains: [
      'api.smartcharge.io',
      'app.smartcharge.io',
      'smartchargeapipd.azurewebsites.net',
      'meshcrafts-demo-api-app-service-stagging.azurewebsites.net'
    ],
    headerName: 'Authorization',
    throwNoTokenError: false,
    authScheme: 'Bearer '
  };
}
