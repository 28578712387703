import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
    loading: Promise<any>|any;
    isLoading: boolean = false;
    
    constructor(public loadingCtrl: LoadingController, private translate: TranslateService) {}

    show(content?:string){
            
        this.isLoading = true;
        content = content ? content : this.translate.instant('APP.PLEASE_WAIT');
        this.loading = this.loadingCtrl.create({
            message: content
        })
        this.loading.then(loader=>{
            loader.present();
        });
    }
    async showUpdate(){
        this.loading = this.loadingCtrl.create({
            message: "Our system is updating.\nWe will be back shortly",
            //spinner: null
        }).then(loader=>{
            loader.present();
        });
        
    }
    dismiss(){
        /*if(this.loading){
            this.loading.dismiss();
        }*/
        if(this.loading instanceof Promise){
            this.loading.then(loader=>{
                if(loader) loader.dismiss();
            })
        }else if(this.loading && this.loading.dismiss){
            this.loading.dismiss();
        }

        /*if(this.isLoading){
            
            this.loading.dismiss();
            this.isLoading = false;
        }*/
    }
}
