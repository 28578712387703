<ion-list [lines]="'full'">
  <ion-item
    [button]="true"
    (click)="close('adhoc')"
    *ngIf="paymentMethods.length > 0">
    <ion-label>
      <ng-container>
        {{ 'ASK_FOR_CREDENTIALS.ADHOC_USER' | translate }}
      </ng-container>
      <div class="logos">
        <ng-container *ngFor="let method of paymentMethods">
          <img
            *ngIf="method.PaymentType === 'Vipps'"
            src="assets/img/payment-methods/VippsSmiley_V2.svg"
            loading="lazy"
            slot="end" />
          <img
            *ngIf="method.PaymentType === 'Payter'"
            src="assets/img/payment-methods/Terminal_V2.svg"
            slot="end" />
          <img
            *ngIf="(platformIs('android') || platformIs('desktop') || platformIs('mobileweb')) &&  ['GooglePayAdhocNorway','GooglePayAdhocSweden'].includes(method.PaymentType)"
            src="assets/img/payment-methods/GooglePay_V2.svg"
            loading="lazy"slot="end" />

          <img
            *ngIf="platformIs('ios') &&  ['ApplePayAdhocNorway','ApplePayAdhocSweden'].includes(method.PaymentType)"
            src="assets/img/payment-methods/ApplePay_V2.svg"
            loading="lazy"slot="end" />

          <img
            *ngIf="method.PaymentType === 'BraintreeCardAdhocNorway' || method.PaymentType === 'BraintreeCardAdhocSweden'"
            src="assets/img/payment-methods/Card_V2.svg"
            loading="lazy"
            slot="end" />
        </ng-container>
      </div>
    </ion-label>
  </ion-item>
  <ion-item [button]="true" (click)="close('login')">
    <ion-label>{{ 'ASK_FOR_CREDENTIALS.LOGIN' | translate }}</ion-label>
  </ion-item>
  <ion-item [button]="true" (click)="close('register')">
    <ion-label>{{ 'ASK_FOR_CREDENTIALS.REGISTER' | translate }}</ion-label>
  </ion-item>
  <ion-item [button]="true" (click)="close('cancel')">
    <ion-label>{{ 'APP.CANCEL' | translate }}</ion-label>
  </ion-item>
</ion-list>
