import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { FilterClass } from 'src/app/classes/filters';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

    filters: FilterClass = new FilterClass();

    constructor(private storage: Storage) {
        this.getFilters();
    }
    getFilters(): Promise<any>{
        return new Promise(resolve=>{
            this.storage.get('filters').then(filters=>{
                if(filters){
                    filters = JSON.parse(filters);
                    this.filters = Object.assign(this.filters, filters);
                    
                }
                resolve(this.filters);
            })
        })
    }
    putFilters(filters?){

        //this.getFilters().then(oldFilters=>{
            this.filters = filters
            this.storage.set('filters', JSON.stringify(this.filters));
    
        //})     
    }
    resetFilters(){
        this.filters = null;
        this.filters = new FilterClass();
        this.storage.remove('filters');
        this.storage.set('filters', JSON.stringify(this.filters));
    }
    countFilters(): Promise<any>{
        return new Promise(resolve=>{
            let defaultFilter:FilterClass = new FilterClass();
            let count = 0;
            this.getFilters().then((filters:FilterClass)=>{
                if(filters.kWh.lower!==defaultFilter.kWh.lower || filters.kWh.upper !==defaultFilter.kWh.upper){
                    count ++;
                }
                if(filters.connectors.length){
                    count += filters.connectors.length;
                }
                if(defaultFilter.smart !== filters.smart){
                    count ++;
                }
                if(filters.range !== defaultFilter.range){
                    count ++;
                }
                resolve(count);
            })
        })
    }
}