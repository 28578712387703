import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
    private isActive: boolean = true;
	private resume: Subject<any>;
	private pause: Subject<any>;

    constructor(private platform: Platform) { }

	viewChange():Subject<any>{
		const subject = new Subject();
	
		if(this.platform.is('cordova')){

			if(this.resume) this.resume.complete();//Close old
			this.resume = this.platform.resume;
			this.resume.subscribe(()=>{
				this.isActive = true;
				subject.next(true);
			})

			if(this.pause) this.pause.complete();//Close old
			this.pause = this.platform.pause;
			this.pause.subscribe(()=>{
				this.isActive = false;
				subject.next(false);
			})
		}else{
			document.addEventListener("visibilitychange", ()=>{
				
				if(document.visibilityState === 'visible'){
					subject.next(true);
				}else{
					subject.next(false);
				}
				this.isActive = document.visibilityState === 'visible';
			});
		}
		return subject;
	}
	

init(){
   
    
}

}
