import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

// export const appVersion = '2.13.13';
const baseURL = 'api.smartcharge.io/v2/';
const sandBoxURL = 'current-api-sandbox.azurewebsites.net/v2/';
const debugURL = 'smartchargeapipd.azurewebsites.net/v2/';
const stagingURL =
  'meshcrafts-demo-api-app-service-stagging.azurewebsites.net/v2/';

@Injectable({
  providedIn: 'root'
})
export class DefaultsService {
  baseUrl: string = 'https://' + baseURL;
  socket: string;
  production: boolean = true;

  constructor(private storage: Storage) {}

  init() {
    this.storage.get('environment').then((env) => {

      this.baseUrl = 'https://' + baseURL;
      this.socket = 'wss://' + baseURL;

      if (env === 'staging') {
        this.baseUrl = 'https://' + stagingURL;
        this.socket = 'wss://' + stagingURL;
      } else if (env === 'sandbox') {
        this.baseUrl = 'https://' + sandBoxURL;
        this.socket = 'wss://' + sandBoxURL;
      }else if (env === 'debug') {
        this.baseUrl = 'https://' + debugURL;
        this.socket = 'wss://' + debugURL;
      } else {
        this.baseUrl = 'https://' + baseURL;
        this.socket = 'wss://' + baseURL;
      }
    });
  }
}
