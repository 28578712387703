import { Injectable } from '@angular/core';
//import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AppConfig } from 'src/app/variables';
import OneSignal from 'onesignal-cordova-plugin';
//const OneSignal1 = (window as any).plugins.OneSignal;

export type pushState = 'unknown' | 'accepted' | 'rejected';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  pushInited: boolean = false;
  state: pushState = 'unknown';
  appConfig: AppConfig = new AppConfig();

  constructor(
    //private oneSignal: OneSignal,
    private platform: Platform,
    private storage: Storage
  ) { }
  login(customerID){
    if(!this.pushInited || !this.platform.is('cordova')) return;
    //OneSignal.login(customerID) //this crashes in ios
  }
  logout(){
    if(!this.pushInited || !this.platform.is('cordova')) return;
    //OneSignal.logout();
  }
  initPush() {

    if (!this.appConfig.pushAppID || !this.appConfig.pushSenderID) return; //Do not set up push if it is not configured. This should not happen

    if (!this.pushInited) {
      if (!this.platform.is('cordova')) return;
      try {
        //OneSignal.setConsentRequired(false);
        //OneSignal.setAppId(this.appConfig.pushAppID);
        OneSignal.initialize(this.appConfig.pushAppID);
        //OneSignal.provideUserConsent(false);
        

        OneSignal.Notifications.addEventListener('foregroundWillDisplay', (notificationReceivedEvent)=>{
          
        })
        /*OneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent)=>{
          notificationReceivedEvent.complete(null)
        })*/
       // OneSignal.setLogLevel(6,0);
        OneSignal.Debug.setLogLevel(6)
        /*OneSignal.setNotificationOpenedHandler(opened=>{
          //Use this to handle when we open notifications
        })*/

        this.pushInited = true;
        
      } catch (err) {
        console.log(err);
      }
    }
  }
  clearPush() {
    if (!this.pushInited) return;
    //this.oneSignal.clearOneSignalNotifications();
    //OneSignal.clearOneSignalNotifications();
    OneSignal.Notifications.clearAll();
  }
  registerPush(): Promise<any> {
    return new Promise((resolve) => {

      if (this.platform.is('cordova')) {
        if (!this.pushInited) this.initPush();
        //OneSignal.provideUserConsent(true)
        //OneSignal.setConsentRequired(true);
       
        /*OneSignal.addSubscriptionObserver(changeEvent=>{
          console.log(changeEvent)
          console.log("UserID: " + changeEvent.to.userId)
          this.storage.set('pushToken', changeEvent.to.userId);
          resolve(changeEvent.to.userId)
        })*/
        try{
          const currentBranded = this.appConfig.appID == 'eco.current' ? 'CURRENTECO_' : '';
          if(OneSignal.Notifications.hasPermission()){
            //Might happen if user logs out and in again
            OneSignal.setConsentGiven(true);
            this.state = 'accepted';
            const userID = currentBranded + OneSignal.User.pushSubscription.id;
            this.storage.set('pushToken', userID);
            resolve(userID)
          }else{
            OneSignal.Notifications.requestPermission(true).then(accepted=>{
         
              if(accepted){
                OneSignal.setConsentGiven(true);
                this.state = 'accepted';
                
                
                const userID = currentBranded + OneSignal.User.pushSubscription.id;
                this.storage.set('pushToken', userID);
                resolve(userID)
              }else{
                this.state = 'rejected';
                resolve(null)
              }
            }, err=>{
              console.log(err)
              this.state = 'rejected';
              resolve(null)
            })
          }
        }catch(err){
          console.log(err)
          resolve(null)
        }
        /*OneSignal.promptForPushNotificationsWithUserResponse((accepted)=> {
          
          if(accepted){
            this.state = 'accepted';

            OneSignal.getDeviceState(state=>{
              this.storage.set('pushToken', state.userId);
              resolve(state.userId)
            })
          }else{
            this.state = 'rejected';
            resolve(null)
          }
            
        });*/
      } else {
        resolve(null);
      }
    });
  }

  unregisterPush() {
    if (this.platform.is('cordova')) {
      //this.push.unregister();
    }
  }
  isPushRegistered(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.platform.is('cordova')) {
        this.registerPush().then((token) => {  
          resolve(OneSignal.Notifications.hasPermission());
          //resolve(OneSignal.User.pushSubscription.optedIn)

         /* OneSignal.getDeviceState(state=>{
            resolve(state.subscribed)
          })*/
        });
      } else {
        reject('not_supported');
      }
    });
  }
}
