<!--<ion-app>
  <ion-router-outlet></ion-router-outlet>
</ion-app>-->
<ion-app [ngStyle]="ionAppStyle">
  <ion-router-outlet *ngIf="isIframe"></ion-router-outlet>
  <span class="auth-as" *ngIf="!isIframe">
    <!--{{brandingCtrl.branding}}-->
    <ion-split-pane *ngIf="largeScreen && ready" contentId="main">
      <ion-menu type="overlay" contentId="main">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ 'APP_NAVIGATION.MENU' | translate }}</ion-title>
          </ion-toolbar>
        </ion-header>

        <ion-content>
          <ion-list class="ion-no-padding">
            <ion-menu-toggle auto-hide="false">
              <ion-item routerDirection="root" routerLink="stations">
                <ion-icon slot="start" name="list-outline"></ion-icon>
                <ion-label>
                  {{ 'APP_NAVIGATION.STATIONS' | translate }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item routerDirection="root" routerLink="map">
                <ion-icon slot="start" name="map-outline"></ion-icon>
                <ion-label>{{ 'APP_NAVIGATION.MAP' | translate }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item routerDirection="root" routerLink="orders">
                <ion-icon slot="start" name="basket-outline"></ion-icon>
                <ion-label>
                  {{ 'APP_NAVIGATION.HISTORY' | translate }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle *canSee="[UserType.Installer, UserType.Operator]" auto-hide="false">
              <ion-item routerDirection="root" routerLink="installer">
                <ion-icon slot="start" name="construct-outline"></ion-icon>
                <ion-label>
                  {{ 'APP_NAVIGATION.INSTALLER' | translate }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle *ngIf="loggedIn$ | async" auto-hide="false">
              <ion-item routerDirection="root" routerLink="settings">
                <ion-icon slot="start" name="settings-outline"></ion-icon>
                <ion-label>
                  {{ 'APP_NAVIGATION.SETTINGS' | translate }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle
              *ngIf="(loggedIn$ | async) === false"
              auto-hide="false">
              <ion-item routerDirection="root" routerLink="login">
                <ion-icon slot="start" name="lock-closed-outline"></ion-icon>
                <ion-label>{{ 'APP_NAVIGATION.LOGIN' | translate }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
        <ion-footer class="menu-footer">
          <div *ngIf="loggedInAs" class="authAs">
            <h4>Logged in as: {{ loggedInAs.Email }}</h4>
            <ion-button color="danger" (click)="logoutAs()">Logout</ion-button>
          </div>
          <img src="assets/img/normal_logo.png" alt="{{ appConfig.title }}" />
          <h4 *ngIf="appConfig.showTitle">{{ appConfig.title }}</h4>
          <p (tap)="showLog()" class="version">
            {{ 'APP_NAVIGATION.VERSION' | translate }} {{ version }}
          </p>
        </ion-footer>
      </ion-menu>
      <ion-router-outlet id="main"></ion-router-outlet>
    </ion-split-pane>

    <span *ngIf="!largeScreen && ready">
      <ion-fab
        *ngIf="loggedInAs"
        class="authAsFab"
        vertical="bottom"
        horizontal="middle"
        slot="fixed">
        <ion-fab-button (click)="openAuthAs()">
          <ion-icon name="person"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-tabs>
        <ion-tab-bar *ngIf="!hideAppBar" slot="bottom">
          <ion-tab-button tab="stations">
            <ion-icon name="list-outline"></ion-icon>
            <ion-label>{{ 'APP_NAVIGATION.STATIONS' | translate }}</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="map">
            <ion-icon name="map-outline"></ion-icon>
            <ion-label>{{ 'APP_NAVIGATION.MAP' | translate }}</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="orders">
            <ion-icon name="basket-outline"></ion-icon>
            <ion-label>{{ 'APP_NAVIGATION.HISTORY' | translate }}</ion-label>
          </ion-tab-button>
          <ion-tab-button *canSee="[UserType.Installer, UserType.Operator]" tab="installer">
            <ion-icon name="construct-outline"></ion-icon>
            <ion-label>{{ 'APP_NAVIGATION.INSTALLER' | translate }}</ion-label>
          </ion-tab-button>
          <ion-tab-button *ngIf="loggedIn$ | async" tab="settings">
            <ion-icon name="settings-outline"></ion-icon>
            <ion-label>{{ 'APP_NAVIGATION.SETTINGS' | translate }}</ion-label>
          </ion-tab-button>
          <ion-tab-button *ngIf="(loggedIn$ | async) === false" tab="login">
            <ion-icon name="lock-closed-outline"></ion-icon>
            <ion-label>{{ 'APP_NAVIGATION.LOGIN' | translate }}</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </span>
  </span>
</ion-app>
