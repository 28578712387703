import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewCardComponent } from './new-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
    declarations: [NewCardComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        IonicModule,
        PipesModule
    ],
    exports: [NewCardComponent]
})
export class NewCardModule { }
