import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PointService {
  

    constructor(private _http: HttpInterceptorService, private alertCtrl: AlertController) { }
    
    giveAccess(pointID): Promise<any>{
        return new Promise(resolve=>{
            this.alertCtrl.create({
                header: 'Email',
                inputs: [
                    {
                        name: 'email',
                        type: 'email',
                        placeholder: 'Email'
                    }
                ],
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Confirm Cancel');
                        }
                    }, {
                        text: 'Ok',
                        handler: (data) => {
                            console.log(data)
                            this._http.get('Groups/AddSomeoneToMyGroup/' + pointID + '/' + data.email + '/').subscribe(res => {

                            })
                        }
                    }
                ]
            }).then(alert => {
                alert.present();
            })
            
        })
    }
    getPoint(pointID){
        return this._http.get(`ChargePoints/${pointID}/true`);
    }
    removeOwner(pointID):Promise<any>{
        return new Promise(resolve=>{
            if(!pointID) return;
            this._http.get('ChargePoints/RemoveDefaultCustomer/'+pointID+'/true').subscribe(res=>{
                resolve(true);
            }, err=>{
                resolve(false);
            })
            
        })
    }
    restart(boxID): Promise<any>{
        return new Promise((resolve, reject)=>{
           
            this._http.get('Commands/Reset/'+boxID+'/1').subscribe(res=>{
                if(res && res.datas && res.datas.PK_CommandRequestID){
                    resolve(res.datas.PK_CommandRequestID);
                }else{
                    reject()
                }
                
            }, err=>{
                reject(err)
            })
        })
        
    }
    setAuth(boxID, auth): Promise<any>{
        return new Promise((resolve, reject)=>{
            this._http.get('Commands/SetDefaultAuthentication/'+boxID+'/'+auth).subscribe(res=>{
                if(res && res.datas && res.datas.PK_CommandRequestID){
                    resolve(res.datas.PK_CommandRequestID);
                }else{
                    reject()
                }
            }, err=>{
                reject(err)
            })
        })
        
    }
    permanentlyLock(pointID, locked:boolean): Promise<any>{
        return new Promise((resolve, reject)=>{
            this._http.get('Commands/SetDefaultPermanentCableLocking/'+pointID+'/'+locked).subscribe(res=>{
                if(res && res.datas && res.datas.PK_CommandRequestID){
                    resolve(res.datas.PK_CommandRequestID);
                }else{
                    reject()
                }
            }, err=>{
                reject(err);
            })
        
        })
    }
    
}
