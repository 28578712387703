import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard/auth-guard.service';

const routes: Routes = [
    { path: 'stations', loadChildren: () => import('./components/list/list.module').then(m => m.ListModule) },
    { path: 'adhocReceipt', loadChildren: () => import('./components/adhoc-receipt/adhoc-receipt.module').then(m => m.AdhocReceiptModule) },
    { path: 'login', canActivate: [AuthGuard], loadChildren: () => import('./components/list/list.module').then(m => m.ListModule) },
    { path: 'logout', loadChildren: () => import('./components/list/list.module').then(m => m.ListModule) },
    { path: 'complete-registration/:validationKEY', loadChildren: () => import('./components/list/list.module').then(m => m.ListModule) },

    { path: 'add-box', canActivate: [AuthGuard], loadChildren: () => import('./components/my-chargers/my-chargers.module').then(m => m.MyChargersModule) },
    { path: 'add-box/:stationID', canActivate: [AuthGuard], loadChildren: () => import('./components/my-chargers/my-chargers.module').then(m => m.MyChargersModule) },

    { path: 'stations/box-list/:stationID', loadChildren: () => import('./components/box-list/box-list.module').then(m => m.BoxListModule) },
    { path: 'stations/box-list/:stationID/:pointID', loadChildren: () => import('./components/box-list/box-list.module').then(m => m.BoxListModule) },
    { path: 'qr/:chargerCode', data: {hideAppBar: true}, loadChildren: () => import('./components/qrcode-landing-page/qrcode-landing-page.module').then(m => m.QRCodeLandingPageModule) },
    { path: 'start/:pointID', data: {hideAppBar: true}, loadChildren: () => import('./components/start-redirect-page/start-redirect-page.module').then(m => m.StartRedirectPageModule) },
    { path: 'stations/charging/:sessionID/:pointID', loadChildren: () => import('./components/charging/charging.module').then(m => m.ChargingModule) },
    { path: 'stations/starting/:requestID/:pointID', loadChildren: () => import('./components/charging/charging.module').then(m => m.ChargingModule) },
    { path: 'stations/charging/:action/:boxID/:sessionID/:pointID', loadChildren: () => import('./components/charging/charging.module').then(m => m.ChargingModule) },
    { path: 'stations/active-sessions', loadChildren: () => import('./components/active-sessions/active-sessions.module').then(m => m.ActiveSessionsModule) },
    { path: 'stations/station/:stationID', loadChildren: () => import('./components/single-station/single-station.module').then(m => m.SingleStationModule) },
    { path: 'stations/my-chargers',canActivate: [AuthGuard], loadChildren: () => import('./components/my-chargers/my-chargers.module').then(m => m.MyChargersModule) },

    { path: 'map', loadChildren: () => import('./components/map/map.module').then(m => m.MapModule) },
    { path: 'map/loc/:center', loadChildren: () => import('./components/map/map.module').then(m => m.MapModule) },
    { path: 'map/box-list/:stationID', loadChildren: () => import('./components/box-list/box-list.module').then(m => m.BoxListModule) },
    { path: 'map/charging/:sessionID/:pointID', loadChildren: () => import('./components/charging/charging.module').then(m => m.ChargingModule) },
    { path: 'map/starting/:requestID/:pointID', loadChildren: () => import('./components/charging/charging.module').then(m => m.ChargingModule) },
    { path: 'map/charging/:action/:boxID/:sessionID/:pointID', loadChildren: () => import('./components/charging/charging.module').then(m => m.ChargingModule) },
    { path: 'map/active-sessions', loadChildren: () => import('./components/active-sessions/active-sessions.module').then(m => m.ActiveSessionsModule) },
    { path: 'map/station/:stationID', loadChildren: () => import('./components/single-station/single-station.module').then(m => m.SingleStationModule) },

    { path: 'orders', canActivate: [AuthGuard], loadChildren: () => import('./components/orders/orders.module').then(m => m.OrdersModule) },
    { path: 'installer', canActivate: [AuthGuard], loadChildren: () => import('./components/installer/installer.module').then(m => m.InstallerModule) },

    { path: 'settings', canActivate: [AuthGuard], loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule) },
    { path: 'settings/profile', canActivate: [AuthGuard], loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule) },
    { path: 'settings/profile/change-password', canActivate: [AuthGuard], loadChildren: () => import('./components/change-password/change-password.module').then(m => m.ChangePasswordModule) },
    { path: 'settings/preferences', canActivate: [AuthGuard], loadChildren: () => import('./components/preferences/preferences.module').then(m => m.PreferencesModule) },
    { path: 'settings/access-cards', canActivate: [AuthGuard], loadChildren: () => import('./components/access-cards/access-cards.module').then(m => m.AccessCardsModule) },
    { path: 'settings/contact', canActivate: [AuthGuard], loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule) },
    { path: 'settings/payment', canActivate: [AuthGuard], loadChildren: () => import('./components/payment-methods/payment-methods.module').then(m => m.PaymentMethodsModule) },

    { path: 'claim-box/:key', loadChildren: () => import('./components/claim-box/claim-box.module').then(m => m.ClaimBoxModule) },

    //Old path to payment dropin. We leave this here for a while so the app will function when app.smartcharge.io is pushed
    { path: 'add-payment/:key', loadChildren: () => import('./components/add-payment-dropin/add-payment-dropin.module').then(m => m.AddPaymentDropinModule) },

    { path: 'frame/add-payment/:key', loadChildren: () => import('./components/add-payment-dropin/add-payment-dropin.module').then(m => m.AddPaymentDropinModule) },
    { path: 'frame/verify-payment/:key', loadChildren: () => import('./components/verify-payment/verify-payment-dropin/verify-payment-dropin.module').then(m => m.VerifyPaymentDropinModule) },

    { path: 'error', loadChildren: () => import('./components/error/error.module').then(m => m.ErrorModule) },

    { path: '', redirectTo: '/stations', pathMatch: 'full' },


]
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
